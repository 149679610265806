import { Outlet, ReactLocation, Router } from 'react-location';
import Compsci120 from './Compsci120';
import Compsci335 from './Compsci335';
import Redirect from './Redirect';

const location = new ReactLocation();

const ContentRouter = () =>
{
	return (
		<Router
			location={location}
			routes={[
				{
					path: '/dco/course/COMPSCI/120/1203',
					element: <Compsci120 />,
				},
				{
					path: '/dco/course/COMPSCI/335/1215',
					element: <Compsci335 />,
				},
				{
					element: <Redirect />,
				},
			]}
		>
			<Outlet />
		</Router>
	);
};

export default ContentRouter;
