import { useEffect } from 'react';

function Compsci335()
{
	useEffect(() =>
	{
		// const link = document.createElement('link');
		// link.rel = 'stylesheet';
		// link.href = '';
		// document.head.appendChild(link);
		document.title = 'COMPSCI 335 Functional Programming and Distributed Services - Course Outlines - University of Auckland';
		// document.querySelector('meta[name="description"]')?.setAttribute('content', document.title);
		return () =>
		{
			// document.head.removeChild(link);
			document.title = 'Course Outlines - University of Auckland';
			// document.querySelector('meta[name="description"]')?.setAttribute('content', document.title);
		};
	}, []);

	return (
		<>
			<header>
				<nav className="navbar navbar-expand-xl navbar-toggleable-xl navbar-light"
					style={{ paddingLeft: 0, paddingRight: 0 }}>

					<div className="container-fluid justify-content-end" style={{ columnGap: '20px', paddingRight: '20px' }}>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
							aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="navbar-collapse collapse d-xl-inline-flex">
							<ul className="navbar-nav flex-grow-1 justify-content-end" style={{ columnGap: '30px' }}>
								<li className="nav-item text-right pr-sm-3 pr-lg-0">
									<a className="nav-link uoa-dark-blue-text" href="https://www.auckland.ac.nz/"
										style={{ whiteSpace: 'nowrap' }}>
										University Home
									</a>
								</li>
								<li className="nav-item text-right pr-sm-3 pr-xl-0">
									<a className="nav-link uoa-dark-blue-text"
										href="https://www.auckland.ac.nz/en/study/study-options.html"
										style={{ whiteSpace: 'nowrap' }}>
										Study options
									</a>
								</li>
								<li className="nav-item text-right pr-sm-3 pr-xl-0">
									<a className="nav-link uoa-dark-blue-text" href="https://canvas.auckland.ac.nz"
										style={{ whiteSpace: 'nowrap' }} title="Go to Canvas">Canvas</a>
								</li>
							</ul>
						</div>
						<a className="w3-btn p-2 rounded" style={{ backgroundColor: '#ebeaea' }}
							href="https://courseoutline.auckland.ac.nz/dco/Login?ReturnUrl=https%3A%2F%2Fcourseoutline.auckland.ac.nz%2Fdco%2Fcourse%2FlatestBySubjectCatalogNumberTerm%3Fsubject%3DCOMPSCI%26catalogNumber%3D320%26term%3D1245"
						>
							<i className="fas fa-user mr-2"></i> Sign In
						</a>
					</div>
				</nav>
				<div id="bannerMargin" className="mt-5 d-none d-print-block">&nbsp;</div>
				<div id="banner" className="mb-5 position-relative" style={{ borderBottom: '68px solid #3f739b' }}>
					<div className="position-absolute" style={{ left: '20px', top: '-14px' }}>
						<a href="https://www.auckland.ac.nz/" title="Go to university home"><img
							src="https://courseoutline.auckland.ac.nz/dco/img/UOA-NT-VC-RGB.png?v=bHAndc4sJL4v1v3WUTQ7q3F6Weo2qVOTYS6wjhufSEE"
							width="100" /></a>
					</div>
					<div className="position-absolute" style={{ left: '150px', top: '10px' }}>
						<a className="navbar-brand" style={{ whiteSpace: 'nowrap' }} href="https://courseoutline.auckland.ac.nz/dco/course">
							<span style={{ fontFamily: 'NationalBook', fontSize: '22px', color: '#ffffff' }}><span
								style={{ fontFamily: 'NationalBold' }}>Course</span> Outlines</span>
						</a>
					</div>
				</div>
			</header>

			<div className="container layout-container">
				<main role="main" className="pb-3">
					<span className="anti-forgery">
						{" "}
						<input
							name="__RequestVerificationToken"
							type="hidden"
							defaultValue="CfDJ8GLK8N7-IyJOliiKY0cP2YfeMtE1hbYvSEvZx2tmsIy9YtZA6O0viduycpWj6XwJiRhfqvgzrJv_30NP8Q3YQleDuZcOZxBpFrI0Wa1So_pfeRG8Jplk1Lb1aE7QJTZJfQy6vuj-dZ-XtnBOP2IueqY"
						/>
					</span>
					<div className="container" style={{ padding: 0 }}>
						<div className="row d-print-none">
							<div className="col-12">
								<div id="searchErrorWrapper" className="collapse">
									<div
										id="searchError"
										className="alert alert-danger mt-2 mb-3"
										role="alert"
										style={{ display: "none" }}
									></div>
								</div>
							</div>
						</div>
						<div className="row d-print-none justify-content-end">
							<div className="col-12 col-md-6 col-lg-8">
								<form action="/dco/course/pdf" method="get" target="_blank">
									{" "}
									<input
										id="courseOutlineId"
										name="courseOutlineId"
										type="hidden"
										defaultValue={89568}
									/>
									<button
										className="w3-btn w3-ripple w3-blue-grey"
										data-loading-text="<i class='fa fa-circle-notch fa-spin'></i>"
										style={{ minWidth: 100 }}
									>
										<i className="fas fa-download mr-2" /> Download PDF
									</button>
								</form>
							</div>
							<div
								id="searchContainer"
								className="col-12 col-md-6 col-lg-4 collapse show"
							>
								<div className="w3-display-container w3-right-align">
									<form
										action="/dco/course/advanceSearch"
										id="searchForm"
										method="get"
									>
										{" "}
										<input
											id="advanceSearchText"
											name="advanceSearchText"
											type="hidden"
										/>
										<input
											id="searchCourse"
											className="w3-input w3-border w3-round mb-0"
											placeholder="Search courses"
										/>
										<a
											id="searchIcon"
											className=" w3-display-bottomright pointer"
											data-loading-text="<i class='fa fa-circle-notch fa-spin' style='font-size:24px; line-height:40px'></i>"
											style={{ right: 4 }}
										>
											<i className="material-icons">search</i>
										</a>
									</form>
								</div>
							</div>
						</div>
						<div className="row no-gutters">
							<div id="editorColumn" className="col-12">
								<div
									id="editor-wrapper"
									className="w3-white w3-card w3-round w3-display-container"
								>
									<div
										id="editor"
										className="pt-xl-3 pl-lg-5 pb-lg-5 pr-lg-5 pt-1 pr-3 pb-3 pl-3"
									>
										<div className="font-italic justify-content-center row">
											<a
												href="https://courseoutline.auckIand.ac.nz/dco/course/COMPSCI/335/1215"
												className="uoa-light-blue-text"
											>
												https://courseoutline.auckIand.ac.nz/dco/course/COMPSCI/335/1215
											</a>
										</div>
										<div className="container pt-lg-3 pt-1 pr-0 pl-0 mb-5">
											<div className="row no-gutters">
												<div className="col-12 w3-center">
													<h1 className="mt-0 mb-1 mb-xl-3">
														COMPSCI 335 : Functional Programming and Distributed Services
													</h1>
												</div>
											</div>
											<div className="row no-gutters">
												<div className="col-12 w3-center">
													<h2>Science</h2>
												</div>
											</div>
											<div className="row no-gutters">
												<div className="col-12 w3-center">
													<h5>
														2021 Semester Two (1215){" "}
														<span className="w3-show-inline-block">(15 POINTS)</span>
													</h5>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-CoursePrescription" />
											<div>
												<h3 className="section-title">Course Prescription</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													Fundamental design techniques used for efficient algorithmic
													problem-solving and software development. Methods that yield
													algorithms that are both provably correct and efficient.
													Efficiency of algorithms to provide a basis for deciding
													which algorithm is best for the job. Limits on the power of
													computers and the theory of NP-completeness. An introduction
													to methods whose correctness or performance is not
													guaranteed.{" "}
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-CourseOverview" />
											<div>
												<h3 className="section-title">Course Overview</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													<p>
														In this course, you will explore the fundamental design
														techniques used for efficient algorithmic problem-solving
														and software development. Methods that yield algorithms
														that are both provably correct and efficient, the
														efficiency of algorithms to provide a basis for deciding
														which algorithm is best for the job, limits on the power
														of computers, and the theory of NP-completeness will also
														be covered. Students will be introduced to methods whose
														correctness or performance is not guaranteed.&nbsp;
													</p>
													<p>
														Algorithmics is the systematic study of the design and
														analysis of algorithms. It deals with such fundamental
														questions as: How do we go about designing an algorithm
														for a given problem? Is the algorithm correct? Does it
														perform efficiently? Is it the best possible for the job?
														Is there any good algorithm for this problem? It has been
														said that algorithms form the soul of computer science.
														Certainly, the study of algorithms is a fundamental
														activity of a computer scientist for society.
													</p>
													<p>
														The skills developed in this course are particularly
														useful for those wishing to have a career involving
														efficient programming, problem-solving and data science.
														&nbsp;The class is considered essential for all students
														interested in continuing to graduate programmes in
														computer science, as it covers ACM curriculum core topics
														deemed required within the area of algorithms for every
														computer science undergraduate major.
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-CourseRequirements" />
											<div>
												<h3 className="section-title">Course Requirements</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													Prerequisite: COMPSCI 220, and COMPSCI 225 or MATHS 254{" "}
												</div>
											</div>
										</div>
										<div id="capabilitiesSection" className="clearfix mb-5">
											<a id="section-CapabilitiesDevelopedinthisCourse" />
											<div>
												<h3 className="section-title">
													Capabilities Developed in this Course
												</h3>
											</div>
											<div
												id="capabilitiesView"
												className="w3-display-container mb-3"
											>
												<table id="capabilitiesViewTable">
													<tbody>
														<tr>
															<td data-nowrap="" valign="top">
																Capability 3:
															</td>
															<td className="pl-3">Knowledge and Practice</td>
														</tr>
														<tr>
															<td data-nowrap="" valign="top">
																Capability 4:
															</td>
															<td className="pl-3">Critical Thinking</td>
														</tr>
														<tr>
															<td data-nowrap="" valign="top">
																Capability 5:
															</td>
															<td className="pl-3">Solution Seeking</td>
														</tr>
														<tr>
															<td data-nowrap="" valign="top">
																Capability 6:
															</td>
															<td className="pl-3">Communication</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div className="w3-left mt-3">
												Graduate Profile:&nbsp;
												<a
													id="programName"
													className="uoa-light-blue-text"
													style={{ textDecoration: "underline" }}
												>
													Bachelor of Science
												</a>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-LearningOutcomes" />
											<div>
												<h3 className="section-title">Learning Outcomes</h3>
											</div>
											<div id="learningOutcomesView" className="w3-display-container">
												<div>
													By the end of this course, students will be able to:
												</div>
												<ol className="mt-2">
													<li>
														Define clearly a computational problem (with input,
														output, and I/O constraint) and discuss how an efficient
														algorithm for solving the latter problem would help to
														resolve the original problem - when given a "real-world"
														problem. Consider social ramifications of your solution
														model.
														<span style={{ paddingLeft: "0.25em" }}>
															(Capability 3, 4 and 5)
														</span>
													</li>
													<li>
														Design an algorithm guaranteed to solve a computational
														problem by using a given design technique from: greedy,
														divide/conquer, dynamic programming, randomized,
														exhaustive search
														<span style={{ paddingLeft: "0.25em" }}>
															(Capability 4 and 5)
														</span>
													</li>
													<li>
														Estimate rigorously the big-Theta running time of a simple
														algorithm devised as above
														<span style={{ paddingLeft: "0.25em" }}>
															(Capability 3 and 4)
														</span>
													</li>
													<li>
														Determine whether a given computational problem can be
														solved by a standard algorithmic technique as above, and
														if so, choose the most promising technique
														<span style={{ paddingLeft: "0.25em" }}>
															(Capability 3 and 5)
														</span>
													</li>
													<li>
														Determine whether a given difficult computational problem
														can be mapped by standard methods to an NP-hard
														algorithmic problem; formally communicate your problem
														reduction
														<span style={{ paddingLeft: "0.25em" }}>
															(Capability 4, 5 and 6)
														</span>
													</li>
												</ol>
											</div>
										</div>
										<div id="assessmentsSection" className="mb-5">
											<a id="section-Assessments" />
											<div>
												<h3 className="section-title">Assessments</h3>
											</div>
											<div id="assessmentTypesTableDiv" className="clearfix mt-2">
												<table
													id="assessment-types"
													className="w3-table w3-bordered w3-border"
												>
													<tbody>
														<tr>
															<th>Assessment Type</th>
															<th className="w3-center" data-width="120px">
																Percentage
															</th>
															<th>Classification</th>
														</tr>
														<tr>
															<td className="assessment-name">Assignments</td>
															<td className="percentage w3-center">
																<span>30</span>
																<span>%</span>
															</td>
															<td>Individual Coursework</td>
														</tr>
														<tr>
															<td className="assessment-name">Final Exam</td>
															<td className="percentage w3-center">
																<span>60</span>
																<span>%</span>
															</td>
															<td>Individual Examination</td>
														</tr>
														<tr>
															<td className="assessment-name">Test</td>
															<td className="percentage w3-center">
																<span>10</span>
																<span>%</span>
															</td>
															<td>Individual Examination</td>
														</tr>
														<tr className="footer-row">
															<td>
																<span id="assessmentTypeCount">3</span>
																<span> types</span>
															</td>
															<td className="w3-center">
																<span id="totalPercentage">100</span>%
															</td>
															<td />
														</tr>
													</tbody>
												</table>
											</div>
											<div
												id="learningOutcomesAssessmentTypeView"
												className="w3-display-container mt-4"
											>
												<table className="w3-table w3-bordered w3-border">
													<tbody>
														<tr>
															<th data-width="50%">Assessment Type</th>
															<th data-width="50%" colSpan={10}>
																Learning Outcome Addressed
															</th>
														</tr>
														<tr style={{ fontWeight: 600 }}>
															<td />
															<td width="5%" className="w3-center">
																1
															</td>
															<td width="5%" className="w3-center">
																2
															</td>
															<td width="5%" className="w3-center">
																3
															</td>
															<td width="5%" className="w3-center">
																4
															</td>
															<td width="5%" className="w3-center">
																5
															</td>
														</tr>
														<tr>
															<td className="w3-left-align">Assignments</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
														</tr>
														<tr>
															<td className="w3-left-align">Final Exam</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
														</tr>
														<tr>
															<td className="w3-left-align">Test</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
															<td className="w3-center">
																<span>
																	<i className="fas fa-check" />
																</span>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													<p>
														Need to get 50% in both the practical and theory
														components of the course to pass.
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-Tuākana" />
											<div>
												<h3 className="section-title">Tuākana</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Tuākana Science is a multi-faceted programme for Māori and
														Pacific students providing topic specific tutorials,
														one-on-one sessions, test and exam preparation and more.
														Explore your options at <br />
														<a
															href="https://www.auckland.ac.nz/en/science/study-with-us/pacific-in-our-faculty.html"
															target="_blank"
															className="uoa-light-blue-text"
														>
															https://www.auckland.ac.nz/en/science/study-with-us/pacific-in-our-faculty.html
														</a>
														<br />
														<a
															href="https://www.auckland.ac.nz/en/science/study-with-us/maori-in-our-faculty.html"
															target="_blank"
															className="uoa-light-blue-text"
														>
															https://www.auckland.ac.nz/en/science/study-with-us/maori-in-our-faculty.html
														</a>
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-KeyTopics" />
											<div>
												<h3 className="section-title">Key Topics</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													<ul>
														<li>
															Algorithm design techniques, including Greedy,
															Divide-Conquer, Dynamic Programming, Network Flow, and
															Randomisation
														</li>
														<li>NP-completeness/hardness and intractability</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-SpecialRequirements" />
											<div>
												<h3 className="section-title">Special Requirements</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													<p>
														Need to know how to program efficiently in one of the
														following languages: Python, C++, Rust, Java, Go, C#
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-WorkloadExpectations" />
											<div>
												<h3 className="section-title">Workload Expectations</h3>
											</div>
											<div className="w3-display-container">
												<div style={{ textAlign: "justify" }}>
													<p>
														This course is a standard 15-point course and students are
														expected to spend 10 hours per week involved in each
														15-point course that they are enrolled in.
													</p>
													<p>For this course, per week, you can expect:</p>
													<p />
													<ul>
														<li>3 hours of lectures</li>
														<li>A 1-hour tutorial (optional)</li>
														<li>4 hours of reading and thinking about the content</li>
														<li>
															4 hours of work on assignments and/or test preparation
														</li>
													</ul>
													<p />
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-DeliveryMode" />
											<div>
												<h3 className="section-title">Delivery Mode</h3>
											</div>
											<div className="w3-display-container">
												<div>
													<h4>Campus Experience</h4>
												</div>
												<div style={{ textAlign: "justify" }}>
													<p>
														Attendance is encouraged for both lectures and tutorials.
														<br />
														Lectures will be available as recordings. Other learning
														activities, including tutorials, will not be available as
														recordings.
														<br />
														The course may include live online events including group
														discussions/tutorials.
														<br />
														Attendance on campus is required for the test and exam.
														<br />
														The activities for the course are scheduled as a standard
														weekly timetable delivery.
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-LearningResources" />
											<div>
												<h3 className="section-title">Learning Resources</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Course materials are made available in a learning and
														collaboration tool called Canvas which also includes
														reading lists and lecture recordings (where available).
													</p>
													<p>
														Please remember that the recording of any class on a
														personal device requires the permission of the instructor.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Various textbooks on algorithms, lecture slides,
														automarker.cs.auckland.ac.nz
														<br />
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-StudentFeedback" />
											<div>
												<h3 className="section-title">Student Feedback</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														During the course Class Representatives in each class can
														take feedback to the staff responsible for the course and
														staff-student consultative committees.
													</p>
													<p>
														At the end of the course students will be invited to give
														feedback on the course and teaching through a tool called
														SET or Qualtrics. The lecturers and course co-ordinators
														will consider all feedback.
													</p>
													<p>
														Your feedback helps to improve the course and its delivery
														for all students.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Additional feedbeek is welcome anytime during the course,
														either directly to lecturers or anonymously via class
														representatives.
													</p>
												</div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-AcademicIntegrity" />
											<div>
												<h3 className="section-title">Academic Integrity</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														The University of Auckland will not tolerate cheating, or
														assisting others to cheat, and views cheating in
														coursework as a serious academic offence. The work that a
														student submits for grading must be the student's own
														work, reflecting their learning. Where work from other
														sources is used, it must be properly acknowledged and
														referenced. This requirement also applies to sources on
														the internet. A student's assessed work may be reviewed
														for potential plagiarism or other forms of academic
														misconduct, using computerised detection mechanisms.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-ClassRepresentatives" />
											<div>
												<h3 className="section-title">Class Representatives</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Class representatives are students tasked with
														representing student issues to departments, faculties, and
														the wider university. If you have a complaint about this
														course, please contact your class rep who will know how to
														raise it in the right channels. See your departmental
														noticeboard for contact details for your class reps.{" "}
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-Copyright" />
											<div>
												<h3 className="section-title">Copyright</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														The content and delivery of content in this course are
														protected by copyright. Material belonging to others may
														have been used in this course and copied by and solely for
														the educational purposes of the University under license.
													</p>
													<p>
														You may copy the course content for the purposes of
														private study or research, but you may not upload onto any
														third party site, make a further copy or sell, alter or
														further reproduce or distribute any part of the course
														content to another person.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-InclusiveLearning" />
											<div>
												<h3 className="section-title">Inclusive Learning</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														All students are asked to discuss any impairment related
														requirements privately, face to face and/or in written
														form with the course coordinator, lecturer or tutor.
													</p>
													<p>
														Student Disability Services also provides support for
														students with a wide range of impairments, both visible
														and invisible, to succeed and excel at the University. For
														more information and contact details, please visit the{" "}
														<a
															href="http://disability.auckland.ac.nz"
															target="_blank"
															className="uoa-light-blue-text"
														>
															Student Disability Services’ website
														</a>{" "}
														<span className="d-none d-print-inline">
															http://disability.auckland.ac.nz
														</span>
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-SpecialCircumstances" />
											<div>
												<h3 className="section-title">Special Circumstances</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														If your ability to complete assessed coursework is
														affected by illness or other personal circumstances
														outside of your control, contact a member of teaching
														staff as soon as possible before the assessment is due.
													</p>
													<p>
														If your personal circumstances significantly affect your
														performance, or preparation, for an exam or eligible
														written test, refer to the University’s{" "}
														<a
															href="https://www.auckland.ac.nz/en/students/academic-information/exams-and-final-results/during-exams/aegrotat-and-compassionate-consideration.html"
															className="uoa-light-blue-text"
															target="_blank"
														>
															aegrotat or compassionate consideration page
														</a>{" "}
														<span className="d-none d-print-inline">
															https://www.auckland.ac.nz/en/students/academic-information/exams-and-final-results/during-exams/aegrotat-and-compassionate-consideration.html
														</span>
														.
													</p>
													<p>
														This should be done as soon as possible and no later than
														seven days after the affected test or exam date.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-LearningContinuity" />
											<div>
												<h3 className="section-title">Learning Continuity</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														In the event of an unexpected disruption, we undertake to
														maintain the continuity and standard of teaching and
														learning in all your courses throughout the year. If there
														are unexpected disruptions the University has contingency
														plans to ensure that access to your course continues and
														course assessment continues to meet the principles of the
														University’s assessment policy. Some adjustments may need
														to be made in emergencies. You will be kept fully informed
														by your course co-ordinator/director, and if disruption
														occurs you should refer to the university website for
														information about how to proceed.
													</p>
													<p>
														The delivery mode may change depending on COVID
														restrictions. Any changes will be communicated through
														Canvas.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-StudentCharterandResponsibilities" />
											<div>
												<h3 className="section-title">
													Student Charter and Responsibilities
												</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														The Student Charter assumes and acknowledges that students
														are active participants in the learning process and that
														they have responsibilities to the institution and the
														international community of scholars. The University
														expects that students will act at all times in a way that
														demonstrates respect for the rights of other students and
														staff so that the learning environment is both safe and
														productive. For further information visit{" "}
														<a
															href="https://www.auckland.ac.nz/en/students/forms-policies-and-guidelines/student-policies-and-guidelines/student-charter.html"
															className="uoa-light-blue-text"
															target="_blank"
														>
															Student Charter
														</a>
														<span className="d-none d-print-inline">
															https://www.auckland.ac.nz/en/students/forms-policies-and-guidelines/student-policies-and-guidelines/student-charter.html
														</span>
														.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
										<div className="mb-5">
											<a id="section-Disclaimer" />
											<div>
												<h3 className="section-title">Disclaimer</h3>
											</div>
											<div className="w3-display-container">
												<div
													className="read-only-content"
													style={{ textAlign: "justify" }}
												>
													<p>
														Elements of this outline may be subject to change. The
														latest information about the course will be available for
														enrolled students in Canvas.
													</p>
													<p>
														In this course students may be asked to submit coursework
														assessments digitally. The University reserves the right
														to conduct scheduled tests and examinations for this
														course online or through the use of computers or other
														electronic devices. Where tests or examinations are
														conducted online remote invigilation arrangements may be
														used. In exceptional circumstances changes to elements of
														this course may be necessary at short notice. Students
														enrolled in this course will be informed of any such
														changes and the reasons for them, as soon as possible,
														through Canvas.
													</p>
												</div>
												<div
													className="additional-content"
													style={{ textAlign: "justify" }}
												></div>
											</div>
										</div>
									</div>
									<div
										className="w3-display-container d-print-none"
										style={{ textAlign: "right" }}
									>
										Published on 08/07/2021 03:12
										p.m.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</div>
									<br />
								</div>
							</div>
						</div>
					</div>
					<div
						id="graduateProfileModal"
						className="modal"
						tabIndex={-1}
						role="dialog"
					>
						<div className="modal-dialog modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h3 className="modal-title" />
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">×</span>
									</button>
								</div>
								<div className="modal-body">
									<div id="openingStatement" />
									<table id="graduateProfileCapabilities" />
									<div id="closingStatement" />
								</div>
								<div className="modal-footer"></div>
							</div>
						</div>
					</div>
				</main>
			</div>


			<footer className="border-top footer text-muted d-print-none">
				<div className="container uoa-footer_legal">
					<div className="w3-left">1.0.0.168.28560</div>
					<ul>
						<li role="presentation"><a href="https://www.auckland.ac.nz/en/accessibility.html">Accessibility</a>
						</li>
						<li role="presentation"><a href="https://www.auckland.ac.nz/en/copyright.html">Copyright</a></li>
						<li role="presentation"><a href="https://www.auckland.ac.nz/en/privacy.html">Privacy</a></li>
						<li role="presentation"><a href="https://www.auckland.ac.nz/en/disclaimer.html">Disclaimer</a></li>
					</ul>
				</div>
			</footer>

			{/* <script
				src="https://courseoutline.auckland.ac.nz/dco/lib/jquery/dist/jquery.min.js?v=T-aPohYXbm0fRYDpJLr-zJ9RmYTswGsahAoIsNiMld4"></script>
			<script
				src="https://courseoutline.auckland.ac.nz/dco/lib/bootstrap/dist/js/bootstrap.bundle.min.js?v=rjQPUmi_GY95f0wcIkgTdg9uyK7Kg04HWQQKqpmOMtA"></script>
			<script
				src="https://courseoutline.auckland.ac.nz/dco/lib/jqueryui/dist/js/jquery-ui.min.js?v=aFKTOlEJsM9tM9kVCVngx3PlkimzYrU281N0z5Q7scQ"></script>
			<script
				src="https://courseoutline.auckland.ac.nz/dco/js/public.min.js?v=JvrnZdKJ815djivIkP62x9iV8ICyPk5jUfA5SpSt9NA"></script> */}

		</>
	);
}

export default Compsci335;
