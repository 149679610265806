import { useEffect } from 'react';

function Redirect()
{
	useEffect(() =>
	{
		window.location.href = 'https://courseoutline.auckland.ac.nz/dco';
	}, []);

	return null;
}

export default Redirect;
